import React, { useState, useRef } from 'react';
import styles from './css/contactForm.module.css'
import emailjs from '@emailjs/browser';
import  Swal from 'sweetalert2';
import Alert from '@mui/material/Alert';
import './css/alert.css';


function ACEEform() {
  const [inputs, setInputs] = useState({});
  const form = useRef();
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
          
    emailjs
      .sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
        publicKey: 'sen47T4EaUgEBXV04',
      })
      .then(
        () => {
          console.log('SUCCESS!');
		  <Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
          Swal.fire({
            title: "Thank you!",
            customClass: "myAlert",
            text: "Your details have been received. You will get a response from us within 24 hours",
            icon: "success",
            timer: "3000",
            timerProgressBar: true,
            timerProgressBarColor:"#9fddfe"
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          Swal.fire({
            title: "Something went wrong",
            customClass: "myAlert",
            text: "Please try filling the form again.",
            icon: "error",
          });
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className={styles.form} >
        
		<div className={styles.flex}>
		<label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			<input
				type="text"
				name="FIRSTNAME"
				value={inputs.FIRSTNAME || ""}
				onChange={handleChange}
				placeholder="Your First Name"
				required
				autocomplete="given-name"
			/>
		</label>
		<label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			<input
				type="text"
				name="LASTNAME"
				value={inputs.LASTNAME || ""}
				onChange={handleChange}
				placeholder=" Your Last Name"
				required
				autocomplete="family-name"
			/>
		</label>
		</div>
      	
          <div className={styles.flex}>
            <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
                <input
				  type="text"
				  name="SERVICE"
				  value="Advanced Career Evolution Excellence (ACEE)"
				  onChange={handleChange}
				  required
				  readOnly
				  placeholder="Advanced Career Evolution Excellence"
				  autocomplete="off"
				/>
      		</label>
      		<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
                <input
				  type="mail"
				  name="COMPANYEMAIL"
				  value={inputs.COMPANYEMAIL || ""}
				  onChange={handleChange}
				  placeholder="enter a valid email address"
				  required
				  autocomplete="url"
				/>
      		</label>
      	   </div>
      <input className={styles.submit} type="submit" />
    </form>
  );
}

function MLAform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="Master Legislative Affairs (MLA)"
					onChange={handleChange}
					required
					readOnly
					placeholder="Master Legislative Affairs (MLA)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}
  
function LBBform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="The Legislative Business Basics (LBB)"
					onChange={handleChange}
					required
					readOnly
					placeholder="The Legislative Business Basics (LBB)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function LLBform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="The Leadman Legislative Brunch (LLB)"
					onChange={handleChange}
					required
					readOnly
					placeholder="The Leadman Legislative Brunch (LLB)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function TiAPform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="The Indispensable Aide Package (TiAP)"
					onChange={handleChange}
					required
					readOnly
					placeholder="The Indispensable Aide Package (TiAP)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function TBIPform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="The Personal brand & Image Projection Course (TBIP)"
					onChange={handleChange}
					required
					readOnly
					placeholder="The Personal brand & Image Projection Course (TBIP)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function Transformform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="Transform"
					onChange={handleChange}
					required
					readOnly
					placeholder="Transform"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function TBPform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="The Brand Projection Course (TBP)"
					onChange={handleChange}
					required
					readOnly
					placeholder="The Brand Projection Course (TBP)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function SMform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="Sales & Marketing"
					onChange={handleChange}
					required
					readOnly
					placeholder="Sales & Marketing"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function SMMform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="Social Media Marketing"
					onChange={handleChange}
					required
					readOnly
					placeholder="Social Media Marketing"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

function ASACform() {
	const [inputs, setInputs] = useState({});
	const form = useRef();
	
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({ ...values, [name]: value }));
	};
  
	const sendEmail = (e) => {
	  e.preventDefault();
			
	  emailjs
		.sendForm('service_4nexwa9', 'template_t4laqkm', form.current, {
		  publicKey: 'sen47T4EaUgEBXV04',
		})
		.then(
		  () => {
			console.log('SUCCESS!');
			<Alert severity="success">Message received. You will get a response from us within 24 hours.</Alert>
			Swal.fire({
			  title: "Thank you!",
			  customClass: "myAlert",
			  text: "Your details have been received. You will get a response from us within 24 hours",
			  icon: "success",
			  timer: "3000",
			  timerProgressBar: true,
			  timerProgressBarColor:"#9fddfe"
			});
		  },
		  (error) => {
			console.log('FAILED...', error.text);
			Swal.fire({
			  title: "Something went wrong",
			  customClass: "myAlert",
			  text: "Please try filling the form again.",
			  icon: "error",
			});
		  },
		);
	};
  
	return (
	  <form ref={form} onSubmit={sendEmail} className={styles.form} >
		  
		  <div className={styles.flex}>
		  <label className={styles.columns50}>First Name <span style={{color:'red'}}>*</span> <br/>
			  <input
				  type="text"
				  name="FIRSTNAME"
				  value={inputs.FIRSTNAME || ""}
				  onChange={handleChange}
				  placeholder="Your First Name"
				  required
				  autocomplete="given-name"
			  />
		  </label>
		  <label className={styles.columns50}>Last Name <span style={{color:'red'}}>*</span>  <br/>
			  <input
				  type="text"
				  name="LASTNAME"
				  value={inputs.LASTNAME || ""}
				  onChange={handleChange}
				  placeholder=" Your Last Name"
				  required
				  autocomplete="family-name"
			  />
		  </label>
		  </div>
			
			<div className={styles.flex}>
			  <label className={styles.columns50}>Course <span style={{color:'red'}}>*</span> <br/>
				  <input
					type="text"
					name="SERVICE"
					value="Advanced Sponsored Adverts Class (ASAC)"
					onChange={handleChange}
					required
					readOnly
					placeholder="Advanced Sponsored Adverts Class (ASAC)"
					autocomplete="off"
				  />
				</label>
				<label className={styles.columns50}>Email <span style={{color:'red'}}>*</span>  <br/>
				  <input
					type="mail"
					name="COMPANYEMAIL"
					value={inputs.COMPANYEMAIL || ""}
					onChange={handleChange}
					placeholder="enter a valid email address"
					required
					autocomplete="mail"
				  />
				</label>
			   </div>
		<input className={styles.submit} type="submit" />
	  </form>
	);
}

export { ACEEform, MLAform, LBBform, LLBform, TiAPform, TBIPform, Transformform, TBPform, SMform, SMMform, ASACform }