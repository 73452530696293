import { ScrollingTextBg } from './assets/modules.js';
import { ACEEform, MLAform, LBBform, LLBform, TiAPform, TBIPform, Transformform, TBPform, SMform, SMMform, ASACform } from './assets/Opt-in-forms.js';
import contactForm from './assets/contactForm.js';
import './assets/Opt-in-forms.js';

const ACEEin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="ACEE"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Advanced Career Evolution Excellence</h3>
          <p className="bigText wt100">Get equipped for today's work realities.</p>
        </div>
        <div className="columns50">
          <ACEEform />
        </div>
      </section>
      
    </div>
    )
}

const MLAin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="MLA"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Master Legislative Affairs</h3>
          <p className="bigText wt100">Get Equipped for the legislative office.</p>
        </div>
        <div className="columns50">
          <MLAform />
        </div>
      </section>
      
    </div>
    )
}

const LBBin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="LB"
        red="B"
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">The Legislative Business Basics (LBB)</h3>
          <p className="bigText wt100">Aclimatize yourself with the legislative practice.</p>
        </div>
        <div className="columns50">
          <LBBform />
        </div>
      </section>
      
    </div>
    )
}

const LLBin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="LLB"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">The Leadman Legislative Brunch (LLB)</h3>
          <p className="bigText wt100">Get to know what the legislative business is all about.</p>
        </div>
        <div className="columns50">
          <LLBform />
        </div>
      </section>
      
    </div>
    )
}

const TiAPin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="TiA"
        red="P"
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">The Indispensable Aide Package (TiAP)</h3>
          <p className="bigText wt100">Become an authority in the legislative business.</p>
        </div>
        <div className="columns50">
          <TiAPform />
        </div>
      </section>
      
    </div>
    )
}

const TBIPin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="TBIP"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">The Personal brand & Image Projection Course (TBIP)          </h3>
          <p className="bigText wt100">Learn how to put yourself and your business out effectively.</p>
        </div>
        <div className="columns50">
          <TBIPform />
        </div>
      </section>
      
    </div>
    )
}

const Transformin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="Trans"
        red="form"
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Transform</h3>
          <p className="bigText wt100">Transform your business.</p>
        </div>
        <div className="columns50">
          <Transformform />
        </div>
      </section>
      
    </div>
    )
}

const TBPin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="TBP"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">The Brand Projection Course (TBP)          </h3>
          <p className="bigText wt100">Your Vision ∞ Our Expertise</p>
        </div>
        <div className="columns50">
          <TBPform />
        </div>
      </section>
      
    </div>
    )
}

const SMin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="SM"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Sales & Marketing          </h3>
          <p className="bigText wt100">Learn how to do your sales and marketing more effectively</p>
        </div>
        <div className="columns50">
          <SMMform />
        </div>
      </section>
      
    </div>
    )
}

const SMMin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="SM"
        red="M"
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Social Media Marketing          </h3>
          <p className="bigText wt100">Know how to leverage social media well.</p>
        </div>
        <div className="columns50">
          <SMMform />
        </div>
      </section>
      
    </div>
    )
}

const ASACin = ()=> {
    return (
      <div className="spacer">
      <ScrollingTextBg
        text="ASAC"
        red="."
      />
      <section className="columns start pt0">
        <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
          <h3 className="overscroll mspacer">Advanced Sponsored Adverts Class (ASAC)          </h3>
          <p className="bigText wt100">Learn how to run ads that truly get results</p>
        </div>
        <div className="columns50">
          <ASACform />
        </div>
      </section>
      
    </div>
    )
}

export { ACEEin, MLAin, LBBin, LLBin, TiAPin, TBIPin, Transformin, TBPin, SMin, SMMin, ASACin }