import React from 'react';
import img from './assets/images/The Lead Man Course d 2.jpg';
import { Link } from "react-router-dom";


function TiAP() {

    return (
        <section className="columns grid100 start">            
            <div className="columns50">
                <div className="card">
                    <img src={img} alt="" className="serviceImg"  />
                </div>
            </div>

            <div className="columns50">
                <h3 className="serviceTitle red">The Indispensable Aide Package (TiAP)</h3>
                <div className="spacer20"></div>
                <p className="">
                    No one is indispensable. This literally means anyone can 
                    do your job.
                    In a world where career success is no longer guaranteed 
                    by the peculiarities of your academic qualifications or 
                    class of it, how do you stand out? How can you carve a 
                    niche for yourself? How can you become Authority in 
                    the legislative Business as a Legislator or even as rookie 
                    Legislative Aide? <br/><br/>
                    TiAP will take you there. TiAP is above average. 
                    No one is indispensable truly but in practical sense, 
                    some people are. 
                    Designed to take you from beginner to expert level, 
                    TiAP takes you above the surface of Legislative practice 
                    and procedures. <br/><br/>
                    THE INDISPENSABLE 
                    AIDE PACKAGE 
                    (TiAP)
                    Leadership | Entrepreneurship | Employability 
                    The LeadMan TiAP will equip you with relevant political and legislative tools required to 
                    operate as an iconic game changer both on the floor and outside the chamber for lasting 
                    political relevance.
                </p>
                <div className="spacer20"></div>
                <Link to="/services/the-indispensable-aide-package/opt-in"><button className="button">Join Next Cohort</button></Link>
            </div>


        </section>
    );
}

export default TiAP;