import React from 'react';
import mla from './assets/images/The Lead Man Course a 2.jpg';
import { Link } from "react-router-dom";


function MLA() {

    return (
        <section className="columns grid100 start">            
            <div className="columns50">
                <div className="card">
                    <img src={mla} alt="" className="serviceImg"  />
                </div>
            </div>

            <div className="columns50">
                <h3 className="serviceTitle red">Master Legislative Affairs (MLA)</h3>
                <div className="spacer20"></div>
                <p className="">Saddled with the powerful yet sensitive 
                    responsibility of nation building are Legislators, LawMakers at the 
                    National and State Assemblies to make laws for the 
                    reformation and dispensation of justice in our 
                    communities. <br /><br />
                    The Master Legislative Affairs (MLA) is specifically 
                    designed with this in mind. 
                    MLA modules are carefully selected with focus on 
                    equipping Legislators and Legislative Aides in the 
                    business of Legislation with professional and political 
                    capacity to function optimally in the capacity of 
                    Reformer, Nation Builders and Justice Dispensers. <br/><br/>
                    Our Facilitators, carefully selected from the Legislative and Legal practice are World Class 
                    trained with experience from the National and State Assemblies across and beyond the 
                    continent. They bring their knowledge and expertise to relatable field and level for all 
                    participants.
                </p>
                <div className="spacer20"></div>
                <Link to="/services/master-legislative-affairs/opt-in"><button className="button">Join Next Cohort</button></Link>
            </div>


        </section>
    );
}

export default MLA;