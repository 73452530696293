import React from 'react';
import { Link } from "react-router-dom";
import { MdOutlineArrowForward } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

function Footer() {
  const footer = (
  <>
    <div className=" borderTop " style={{backgroundColor:"#202020"}}>
      <section className="columns footerGrid pt0 pb0">
        <div className="columns3 right" style={{paddingTop:"5px"}}>
            <div className="columns grid50 social mcolumns mcenter">
              <Link to="https://www.facebook.com/theleadmanafrica/"><FaFacebook/></Link> 
              <Link to="https://www.instagram.com/theleadmanafrica/"><FaInstagram/></Link> 
              <Link to="https://www.linkedin.com/company/the-leadman-africa"><FaLinkedin/></Link>
           </div>
        </div>
        <div className="columns3 center">
           <p className="footerP">
               © 2021 - 2024 TheLeadman Africa <br/>
               <a href="https://www.biteagency.pro" style={{ fontFamily:"Manrope", fontSize:"12px"}} className='wt200 mHide'>website designed and developed by 
               <span className="red"> BITe Agency</span> <MdOutlineArrowForward className="down"/></a>
           </p>
        </div>
        <div className="columns3 right">
            <div className="columns mcolumns grid20 alignright mcenter wt200">
                <Link to="/about-us" className="footerSmP">
                   About Us
                </Link>

                <Link to="/services" className="footerSmP">
                   Services
                </Link>
                <Link to="privacy-policy" className="footerSmP">
                   Privacy Policy
                </Link>
                <div className="mspacer"></div>
            </div>
        </div>
      </section>
    </div>

    <div className=" borderTop p5 center dHide" style={{backgroundColor:"#202020", fontFamily:"Manrope", fontSize:"12px"}}>
      <a href="https://www.biteagency.pro" className="wt200">website designed and developed by <span className="red"> BITe Agency</span> <MdOutlineArrowForward className="down"/></a>
    </div>
  </>
  );
  
return footer;
}

export default Footer;