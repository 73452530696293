import React from 'react';
import { Link } from "react-router-dom";
import './assets/css/about.css';
import divider from './assets/css/divider.svg';
import { ScrollingTextBg, Contact } from './assets/modules.js';
import { MdOutlineArrowDropDown } from 'react-icons/md';


function Services() {

    return (
        <>
            <aside className="spacer20 heroTop">
                <div className="hero">
                    <div className="heroLeft spacer100"><div className="spacer100"></div>
                    <span className="tag uppercase">Our mission</span>
                    <div className="spacer20"></div>
                    <h1>Improving productivity for <span style={{color:'#ff0000'}}>relevance</span> and Impact<span style={{color:'#ff0000'}}>.</span></h1>  
                    <div className="mspacer"></div>
                    <p className="mspacer">We are on a mission to build and improve the efficiency of young professionals and aspiring leaders</p>
                    <div className="spacer50 mspacer"><div className="mspacer"></div>
                        <a href="/contact-us">
                        <button className="button">Help me get better
                        </button>
                        </a>
                    </div> 
                    </div>
                    
                </div>
                <div className="flex flcenter nooverflowx" id="divider"></div>
                </aside>
            {/*///////////////////////////////////////EMPOWERING/////////////////////////////////////////////////////////*/}

            <section className=""><div className="spacer50"></div>
            <h2 className="pad mnopad">Empowering young professionals and business owners with the <span className="red">potentials needed</span> to stay relevant</h2>
            <div className="columns">
                <div className="columns50 mHide"></div>
                <div className="columns50 spacer50">
                <p> We are a 21st Century Human 
                    Capital Development & Image Management Company. We empower Organizations, Businesses and Individuals with 21st century tools 
                    required for performance and productivity that translates to relevance, impact, 
                    influence, profit and sustainability.
                </p>
                <div className="spacer50"></div>
                <Link to="portfolio"><button className="button">View our training portfolio</button> </Link>
                </div>
            </div>
            <div className="spacer100"></div>
            </section>    

            {/* /////// VISION  &   MISSION         VISION  &   MISSION             VISION  &   MISSION */}

            <section className="columns pad grid100 ">
                <div className="columns50 card">
                    <span className="tag uppercase">Our Vision</span>
                    <div className="spacer20"></div>
                    <p> To raise and empower Young 
                        Professionals and Business 
                        Owners with indispensability 
                        potentials required for 
                        relevance and impact through 
                        21st century tools
                    </p> 
                </div>

                <div className="columns50 card">
                    <span className="tag uppercase">Our Mission</span>
                    <div className="spacer20"></div>
                    <p> To spread efficiency and 
                        improve productivity for 
                        relevance and Impact.
                    </p> 
                </div>
            </section> 

            { /*/////// CONTACT.    CONTACT.       CONTACT           CONTACT           */ }
            
            <Contact />
        </>
    );
}

export default Services;